import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import validator from "validator";
import $ from "jquery";
import { baseUrl } from "../../common/common.jsx";
import { Dropdown } from "flowbite-react";
import { HiCheck } from "react-icons/hi";
import "../../index.css";
import ImportComponent from "../Import/Import.jsx";
import { DatePicker } from "antd";
import Snackbar from '@mui/material/Snackbar';
import ListItemIcon from '@mui/material/ListItemIcon';
import dayjs from 'dayjs';
import { Tooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';
import { WindmillSpinner } from 'react-spinner-overlay';
import { Doughnut } from 'react-chartjs-2';
// import { Chart, ArcElement } from 'chart.js';
import pending from "./images/pending.svg";
import CommonSidebarFooter from '../../common/layouts/CommonSidebarFooter.jsx';
// import Confetti from 'react-confetti-canvas';
import { LogoComponent, PreviewLogoComponent } from "./Common.jsx";
import CommonSidebarHeader from "../../common/layouts/CommonSidebarHeader.jsx";
import ProgramAddComponent from "../programs/AddProgram.jsx";
// Chart.register(ArcElement);
import Nodata from "../nodata/Nodata.jsx";
export default function StaffList() {

  const navigate = useNavigate();

  const [roles, setRoles] = useState([]);
  const [permissions, setPermissions] = useState([]);
  const [selectedPermissions, setSelectedPermissions] = useState([]);
  const [programs, setPrograms] = useState([]);
  const [selectedPrograms, setSelectedPrograms] = useState([]);

  const [progress, setProgress] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const [user, setUser] = useState({
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    dob: "",
    joining_date: "",
    address: "",
    role_id: "",
    profile_image: null
  });

  const convertDBDateFormat = (dateString) => {
    const [day, month, year] = dateString.split('-');
    return `${year}-${month}-${day}`;
  };

  const formatDate = (isoDate) => {
    const date = new Date(isoDate);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };
  const dateFormatList = 'DD-MM-YYYY';

  const ondobChange = (date, dateString) => {
    setUser({ ...user, dob: dateString });
    console.log(date, dateString);
  };

  const ondojChange = (date, dateString) => {
    setUser({ ...user, joining_date: dateString });
    console.log(date, dateString);
  }

  const [displayUser, displaySetUser] = useState({
    id: "",
    first_name: "",
    email: "",
    phone: "",
    dob: "",
    joining_date: "",
    address: "",
    assign_classes: "",
    role_id: "",
    permissions_id: "",
    profile_image: null,
    staff_color: ""
  });

  const onSubmit = (ev) => {
    if (validateForm()) {
      const postData = {
        school_id: localStorage.getItem("schoolId"),
        job_title: "",
        first_name: user.first_name ?? "",
        last_name: "",
        gender: "",
        email: user.email ?? "",
        phone: user.phone ?? "",
        dob: user.dob !== "" ? convertDBDateFormat(user.dob) : "",
        joining_date: user.joining_date !== "" ? convertDBDateFormat(user.joining_date) : "",
        // dob: convertDBDateFormat(user.dob),
        // joining_date: convertDBDateFormat(user.joining_date),
        profile_image: user.profile_image ?? "",
        blood_group: "",
        address: user.address ?? "",
        assign_classes: selectedPrograms.join(","),
        role_id: user.role_id ?? "",
        permissions_id: selectedPermissions.join(","),
        status: 1,
        created_by: localStorage.getItem('loginUserId'),
        updated_by: localStorage.getItem('loginUserId'),
        created_on: "",
        updated_on: "",
      };
      console.log(postData);
      setError(null);
      axios({
        method: "post",
        url: baseUrl + "/api/staff_add",
        data: postData,
        headers: {
          "Content-Type": "multipart/form-data",
          "Authorization": localStorage.getItem('access_token')
        },
      }).then((response) => {
        if (response["data"]["status"] === true) {
          setSnackbarMessage(response.data.message);
          setMessageType('success');
          handleSnackbarOpen();
          displaySetUser({
            first_name: response["data"]["data"]["first_name"],
            programs_id: response["data"]["data"]["programs_id"],
            email: response["data"]["data"]["email"],
            phone: response["data"]["data"]["phone"],
            dob: response["data"]["data"]["dob"],
            joining_date: response["data"]["data"]["joining_date"],
            address: response["data"]["data"]["address"],
            assign_classes: response["data"]["data"]["assign_classes"],
            role_id: response["data"]["data"]["role_id"],
            permissions_id: response["data"]["data"]["permissions_id"],
            profile_image: response["data"]["data"]["profile_image"],
            staff_color: response["data"]["data"]["staff_color"]
          });
          $(".overlay").removeClass("visible");
          $(".popup-modal-container").removeClass("visible");
          $(".popup-student-details").addClass("visible");
          // setTimeout(function () {
          //   $(".popup-student-details").addClass("visible");
          // }, 500);
          setUser({
            first_name: "",
            email: "",
            phone: "",
            dob: "",
            joining_date: "",
            address: "",
            role_id: "",
            profile_image: null
          });
          fetchStaffData();
          setSelectedPermissions([]);
          setSelectedPrograms([]);
          setSelectedImage(null);
          console.log(response);
        } else {
          setSnackbarMessage(response.data.message);
          setMessageType('error');
          handleSnackbarOpen();
        }
      });
    }
  };

  const handleImportStatus = (status) => {
    if (status == true) {
      fetchStaffData();
      setimportstatus(false);
    }
  };

  const updateUser = () => {
    const postData = {
      id: studentId,
      school_id: localStorage.getItem("schoolId"),
      job_title: "",
      first_name: user.first_name ?? "",
      last_name: "",
      gender: "",
      email: user.email ?? "",
      phone: user.phone ?? "",
      dob: user.dob !== "" ? convertDBDateFormat(user.dob) : "",
      joining_date: user.joining_date !== "" ? convertDBDateFormat(user.joining_date) : "",
      // dob: convertDBDateFormat(user.dob),
      // joining_date: convertDBDateFormat(user.joining_date),
      blood_group: "",
      address: user.address ?? "",
      assign_classes: selectedPrograms.join(","),
      role_id: user.role_id ?? "",
      permissions_id: selectedPermissions.join(","),
      status: 1,
      created_by: localStorage.getItem('loginUserId'),
      updated_by: localStorage.getItem('loginUserId'),
      created_on: "",
      updated_on: "",
      profile_image: user.profile_image ?? ""
    };
    setError(null);
    console.log(postData);
    if (validateForm()) {
      axios({
        method: "post",
        url: baseUrl + "/api/staff_add",
        data: postData,
        headers: {
          "Content-Type": "multipart/form-data",
          "Authorization": localStorage.getItem('access_token')
        },
      }).then((response) => {
        if (response["data"]["status"] === true) {
          setSnackbarMessage(response.data.message);
          setMessageType('success');
          handleSnackbarOpen();
          displaySetUser({
            id: response["data"]["data"]["id"],
            first_name: response["data"]["data"]["first_name"],
            programs_id: response["data"]["data"]["programs_id"],
            email: response["data"]["data"]["email"],
            phone: response["data"]["data"]["phone"],
            dob: response["data"]["data"]["dob"] === "" ? null : response["data"]["data"]["dob"],
            joining_date: response["data"]["data"]["joining_date"] === "" ? null : response["data"]["data"]["joining_date"],
            address: response["data"]["data"]["address"],
            assign_classes: response["data"]["data"]["assign_classes"],
            role_id: response["data"]["data"]["role_id"],
            permissions_id: response["data"]["data"]["permissions_id"],
            profile_image: response["data"]["data"]["profile_image"],
            staff_color: response["data"]["data"]["staff_color"]
          });
          $(".overlay").removeClass("visible");
          $(".popup-modal-container").removeClass("visible");
          $(".popup-student-details").addClass("visible");
          // setTimeout(function () {
          //   $(".popup-student-details").addClass("visible");
          // }, 500);
          setUser({
            first_name: "",
            email: "",
            phone: "",
            dob: "",
            joining_date: "",
            address: "",
            role_id: "",
            profile_image: null
          });
          setSelectedPermissions([]);
          setSelectedPrograms([]);
          fetchStaffData();
          // localStorage.setItem('userId', response['data']['user_id']);
          console.log(response);
        } else {
          setSnackbarMessage(response.data.message);
          setMessageType('error');
          handleSnackbarOpen();
        }
      });
    }
  };

  const openStudentPopup = (studentData, index) => {
    setRefreshAddStaffForm(true);
    setTimeout(function () {
      setRefreshAddStaffForm(false);
    }, 50);
    fetchAttendanceDetails(studentData.id);
    setStaffPreviewIndex(index)
    displaySetUser({
      id: studentData.id,
      first_name: studentData.first_name,
      programs_id: studentData.programs_id,
      email: studentData.email,
      phone: studentData.phone,
      dob: studentData.dob,
      joining_date: studentData.joining_date,
      address: studentData.address,
      assign_classes: studentData.assign_classes,
      role_id: studentData.role_id,
      profile_image: studentData.profile_image,
      permissions_id: studentData.permissions_id,
      staff_color: studentData.staff_color
    });
    $(".overlay").removeClass("visible");
    $(".popup-modal-container").removeClass("visible");
    $(".popup-student-details").addClass("visible");
    // setTimeout(function () {
    //   $(".popup-student-details").addClass("visible");
    // }, 500);
  };

  const [staffData, setStaffData] = useState({});
  const [loading, setLoading] = useState(false);
  const [importstatus, setimportstatus] = useState(false);
  const [programId, setProgramId] = useState(false);
  const [activeStatus, setActiveStatus] = useState(1);
  const [filterValue, setFilterValue] = useState('');
  const handleInputChange = async (event) => {
    setimportstatus(false);
    setFilterValue(event.target.value);
    try {
      const postData = {
        school_id: localStorage.getItem("schoolId"),
        search: event.target.value.trim(),
        programs_id: "",
        status: activeStatus,
      };
      const { data } = await axios.post(baseUrl + "/api/get_staff", postData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            "Authorization": localStorage.getItem('access_token')
          }
        });
      console.log(data.data, "d");
      setStaffData(data.data);
      // setLoading(true);
    } catch (error) {
      console.log(error);
    }
  };

  /* 31-07-2024 */
  const clearInput = () => {
    setFilterValue('');
    fetchStaffData();
  };

  const fetchStaffData = async () => {
    axios({
      method: "post",
      url: baseUrl + "/api/get_staff",
      data: {
        school_id: localStorage.getItem("schoolId"),
        search: "",
        programs_id: "",
        status: activeStatus,
      },
      headers: {
        "Content-Type": "multipart/form-data",
        "Authorization": localStorage.getItem('access_token')
      },
    }).then((response) => {
      console.log(response);
      if (response.data.status === true) {
        setStaffData(response.data.data);
        setLoading(true);
        console.log(response);
      }
    });
  }

  // const fetchStaffData = async () => {
  //   setLoading(false);
  //   try {
  //     const postData = {
  //       school_id: localStorage.getItem("schoolId"),
  //       search: "",
  //       programs_id: "",
  //       status: activeStatus,
  //     };
  //     const { data } = await axios.post(baseUrl + "/api/get_staff", postData, {
  //       headers: {
  //         "Content-Type": "multipart/form-data",
  //         "Authorization": localStorage.getItem('access_token')
  //       }
  //     });
  //     setStaffData(data.data);
  //     setLoading(true);
  //     console.log(staffData, "d");
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };
  const [studentId, setStudentId] = useState(null);
  const openStudentUpdatePopup = (studentData) => {
    $(".popup-student-details").removeClass("visible");
    setRefreshAddStaffForm(true);
    setTimeout(function () {
      setRefreshAddStaffForm(false);
    }, 50);
    setError(null);
    setValidationErrors({});
    setStudentId(studentData.id);
    setUser({
      first_name: studentData.first_name,
      email: studentData.email,
      phone: studentData.phone,
      last_name: studentData.first_name,
      role_id: studentData.role_id,
      dob: studentData.dob !== null ? formatDate(studentData.dob) : "",
      joining_date: studentData.joining_date !== null ? formatDate(studentData.joining_date) : "",
      address: studentData.address,
      profile_image: studentData.profile_image
    });
    if (studentData.permissions_id !== null && studentData.permissions_id !== "") {
      setSelectedPermissions(studentData.permissions_id.split(","));
    } else {
      setSelectedPermissions([]);
    }
    if (studentData.assign_classes !== null && studentData.assign_classes !== "") {
      setSelectedPrograms(studentData.assign_classes.split(","));
    } else {
      setSelectedPrograms([]);
    }
    $(".overlay").addClass("visible");
    $(".popup-modal-container").addClass("visible");
  };
  const [programsData, setProgramsData] = useState({});
  const [programsLoading, setProgramsLoading] = useState(false);
  const fetchProgramsData = async () => {
    setProgramsLoading(false);
    try {
      const postData = {
        school_id: localStorage.getItem("schoolId"),
        // school_id:24
      };
      const { data } = await axios.post(
        baseUrl + "/api/get_programs",
        postData,
        {
          headers: {
            "Authorization": localStorage.getItem('access_token')
          }
        }
      );
      setProgramsData(data.data);
      setProgramsLoading(true);
    } catch (error) {
      console.log(error);
    }
  };
  const fetchRoles = async () => {
    try {
      const response = await axios.post(
        baseUrl + "/api/get_role",
        {},
        {
          headers: {
            "Authorization": localStorage.getItem('access_token')
          }
        }
      );
      setRoles(response.data.data);
    } catch (error) {
      console.error("Error fetching roles:", error);
      setError("Failed to fetch roles");
    }
  };
  const handlePermissionChange = (permissionId) => {
    const newSelection = selectedPermissions.includes(permissionId)
      ? selectedPermissions.filter((id) => id !== permissionId)
      : [...selectedPermissions, permissionId];
    setSelectedPermissions(newSelection);
  };
  const handleProgramsChange = (programId) => {
    setSelectedPrograms((prevSelected) =>
      prevSelected.includes(programId)
        ? prevSelected.filter((id) => id !== programId)
        : [...prevSelected, programId]
    );
  };
  const fetchPermissions = async () => {
    try {
      const response = await axios.post(
        baseUrl + "/api/get_permission",
        {},
        {
          headers: {
            "Authorization": localStorage.getItem('access_token')
          }
        }
      );
      setPermissions(response.data.data);
    } catch (error) {
      console.error("Error fetching permissions:", error);
    }
  };

  const importStudents1 = () => {
    setimportstatus(true);
  };
  const fetchPrograms = async () => {
    try {
      const response = await axios.post(
        baseUrl + "/api/get_programs",
        {
          school_id: localStorage.getItem("schoolId"),
        },
        {
          headers: {
            "Authorization": localStorage.getItem('access_token')
          }
        }
      );
      setPrograms(response.data.data);
    } catch (error) {
      console.error("Error fetching programs:", error);
    }
  };

  //------------------- import students -----------------
  const [selectedImport, setSelectedImport] = useState("");
  const importStudents = (event) => {
    const files = event.target.files;
    //setUser({ ...user, profile_image: files[0] });
    const importFile = event.target.files[0];

    axios({
      method: "post",
      url: baseUrl + "/api/staff_import",
      data: {
        school_id: localStorage.getItem("schoolId"),
        file: importFile,
      },
      headers: {
        "Content-Type": "multipart/form-data",
        "Authorization": localStorage.getItem('access_token')
      },
    }).then((response) => {
      console.log(response);
      if (response["data"]["status"] === true) {
        fetchStaffData();
        console.log(response);
      }
    });
    //setSelectedImport(file);
  };
  // ------------------------------------------------------
  const selectedRoles = roles.find((data) => data.id === user.role_id);
  // const selectedFoodTime = foodTimeData.find(data => data.id === foodDetails.foodTime);


  const firstNameInputRef = useRef(null);
  const emailInputRef = useRef(null);
  const pnoneInputRef = useRef(null);
  const dobInputRef = useRef(null);
  const joingInputRef = useRef(null);
  const adressInputRef = useRef(null);
  const roleInputRef = useRef(null);
  const programsInputRef = useRef(null);
  const submitButtonRef = useRef(null);
  const handleKeyPress = (event, nextInputRef) => {
    if (event.key === "Enter") {
      event.preventDefault();
      console.log("Next input ref:", nextInputRef);
      if (nextInputRef && nextInputRef.current) {
        nextInputRef.current.focus();
      } else {
        console.log("Next input ref is null");
      }
    }
  };
  const [error, setError] = useState(null);
  const [validationErrors, setValidationErrors] = useState({
    first_name: "",
    email: "",
    phone: "",
    dob: "",
    joining_date: "",
    address: "",
    role_id: "",
    programs: "",
    permissions_id: "",

    // Add other fields here
  });
  const validateForm = () => {
    let errors = {};
    if (user.first_name === "" || user.first_name === null) {
      errors.first_name = "Staff name cannot be empty";
    }
    // if (user.email === "") {
    //   errors.email = "Staff email cannot be empty";
    // }
    // else if (!validator.isEmail(user.email)) {
    //   errors.email = "Please enter valid email";
    // }
    if (user.phone.trim() === "" || user.phone.trim() === null) {
      errors.phone = "Staff phone number cannot be empty";
    } else if (
      !validator.isMobilePhone(user.phone.trim()) ||
      user.phone.trim().length !== 10
    ) {
      errors.phone = "Please enter valid mobile number";
    }
    // if (user.dob === "") {
    //   errors.dob = "Staff date of birth cannot be empty";
    // }
    // if (user.joining_date === "") {
    //   errors.joining_date = "Staff joining date cannot be empty";
    // }
    if (user.address === "" || user.address === null) {
      errors.address = "Staff address cannot be empty";
    }
    if (user.role_id === "" || user.role_id === null) {
      errors.role_id = "Staff role cannot be empty";
    }
    if (user.role_id !== "" || user.role_id !== null) {
      if (user.role_id === 1) {
        if (selectedPrograms.length === 0) {
          errors.programs = "Staff Programs cannot be empty";
        }
      }
    }
    // if (selectedPermissions.length === 0) {
    //   errors.permissions_id = "At least one permission must be selected.";
    // }
    setValidationErrors(errors);
    return Object.keys(errors).length === 0;
  };
  const handleTabSelect = (event) => {
    // Change background color of the selected tab
    document.querySelectorAll(".select_field_point").forEach((element) => {
      element.style.backgroundColor = "";
    });
    event.currentTarget.style.backgroundColor = "#F4F6FF";
  };
  const handleTabUnSelect = () => {
    document.querySelectorAll(".select_field_point").forEach((element) => {
      element.style.backgroundColor = "";
    });
  };

  // ------11-06----------------
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [messageType, setMessageType] = useState('');
  const [snackbarState, setSnackbarState] = useState({
    open: false,
    vertical: 'top',
    horizontal: 'center'
  });
  const handleSnackbarClose = () => {
    setSnackbarState({ ...snackbarState, open: false });
  };
  const handleSnackbarOpen = () => {
    setSnackbarState({ ...snackbarState, open: true });
  };
  useEffect(() => { }, [snackbarMessage, messageType]);


  // --------12-06-----------------

  const [selectedStaff, setselectedStaff] = useState([]);

  // const handleSelectAll = (e) => {
  //   if (e.target.checked) {
  //     const allStudentIds = staffData.map(student => student.id);
  //     setselectedStaff(allStudentIds);
  //   } else {
  //     setselectedStaff([]);
  //   }
  // };
  
  const handleSelectAll = (e) => {
    if (e.target.checked) {
      const allStudentIds = staffData
        .filter(student => student.is_main_admin !== 1)
        .map(student => student.id);
      setselectedStaff(allStudentIds);
    } else {
      setselectedStaff([]);
    }
  };
  
  const handleSelectStudent = (studentId) => {
    setselectedStaff(prevSelected =>
      prevSelected.includes(studentId)
        ? prevSelected.filter(id => id !== studentId)
        : [...prevSelected, studentId]
    );
  };

  // ----------------------13-06-----------------------
  const [isRecycleBinVisible, setRecycleBinVisible] = useState(false);

  const handleRecycleBinClick = () => {
    if (selectedStaff.length !== 0) {
      setRecycleBinVisible(true);
    } else {
      setSnackbarMessage("Please Select Staff");
      setMessageType('error');
      handleSnackbarOpen();
    }
  };

  const handleRecycleBinClose = () => {
    setRecycleBinVisible(false);
  };

  const handleSaveRecycleBin = () => {
    if (selectedStaff.length !== 0) {
      try {
        axios({
          method: "post",
          url: baseUrl + "/api/staff_delete",
          data: {
            staff_ids: selectedStaff,
            updated_by: localStorage.getItem('loginUserId')
          },
          headers: {
            "Content-Type": "multipart/form-data",
            "Authorization": localStorage.getItem('access_token')
          },
        }).then((response) => {
          console.log(response);
          if (response.data.status === true) {
            setRecycleBinVisible(false);
            setselectedStaff([])
            setSnackbarMessage(response.data.message);
            setMessageType('success');
            handleSnackbarOpen();
            fetchStaffData();
          } else {
            setSnackbarMessage(response.data.message);
            setMessageType('error');
            handleSnackbarOpen();
          }
        });
      } catch (error) {
        console.log(error)
      }
    }
  }

  const [isNewProgramVisible, setNewProgramVisible] = useState(false);
  const [newProgram, setNewProgram] = useState(null);
  const [newProgramError, setNewProgramError] = useState("");
  const selectedNewProgram = programs.find(
    (program) => program.id === newProgram
  );


  const handleNewProgramClick = () => {
    if (selectedStaff.length !== 0) {
      setNewProgramVisible(true);
    } else {
      setSnackbarMessage("Please Select Student");
      setMessageType('error');
      handleSnackbarOpen();
    }
  };

  const handleNewProgramClose = () => {
    setNewProgram(null);
    setNewProgramVisible(false);
  };

  const handleSaveNewProgram = async () => {
    if (newProgram === null) {
      setNewProgramError("Please select Program")
    } else {
      setNewProgramError(null)
    }
    if (newProgram !== null && selectedStaff.length !== 0) {
      try {
        axios({
          method: "post",
          url: baseUrl + "/api/staff_move_program",
          data: {
            staff_ids: selectedStaff,
            programs_id: newProgram,
            school_id: localStorage.getItem("schoolId"),
            updated_by: localStorage.getItem('loginUserId')
          },
          headers: {
            "Content-Type": "multipart/form-data",
            "Authorization": localStorage.getItem('access_token')
          },
        }).then((response) => {
          console.log(response);
          if (response.data.status === true) {
            setNewProgramVisible(false);
            setselectedStaff([]);
            setNewProgram(null);
            setSnackbarMessage(response.data.message);
            setMessageType('success');
            handleSnackbarOpen();
            fetchStaffData()
          } else {
            setSnackbarMessage(response.data.message);
            setMessageType('error');
            handleSnackbarOpen();
          }
        });
      } catch (error) {
        console.log(error)
      }
    }
  }

  const handleActiveClick = async (activeStatus) => {
    setActiveStatus(activeStatus)
    setimportstatus(false);
    setLoading(false);
    try {
      axios({
        method: "post",
        url: baseUrl + "/api/get_staff",
        data: {
          school_id: localStorage.getItem("schoolId"),
          search: "",
          programs_id: "",
          status: activeStatus,
        },
        headers: {
          "Content-Type": "multipart/form-data",
          "Authorization": localStorage.getItem('access_token')
        },
      }).then((response) => {
        console.log(response);
        if (response.data.status === true) {
          setStaffData(response.data.data);
          setLoading(true);
          console.log(response);
        }
      });
    } catch (error) {
      console.log(error);
    }
  };


  // ----17-06--------------------
  const [checkedState, setCheckedState] = useState(
    new Array(programs.length).fill(false)
  );
  const [checkedIds, setCheckedIds] = useState([]);
  const handleCheckboxChange = (index) => {
    const updatedCheckedState = [...checkedState];
    updatedCheckedState[index] = !updatedCheckedState[index];
    setCheckedState(updatedCheckedState);

    const id = programs[index].id;
    if (updatedCheckedState[index]) {
      setCheckedIds((prevCheckedIds) => [...prevCheckedIds, id]);
    } else {
      setCheckedIds((prevCheckedIds) =>
        prevCheckedIds.filter((checkedId) => checkedId !== id)
      );
    }
  };

  // -------------------------19-06-------------------------

  const [newProgramPopupVisible, setNewProgramPopupVisible] = useState(false);
  const [newProgramAddUpdateStatus, setNewProgramAddUpdateStatus] = useState(null);
  const handleOpenNewProgramPopupVisible = (status) => {
    setNewProgramAddUpdateStatus(status);
  }

  const [teachersData, setTeachersData] = useState([]);
  const fetchTeachersData = async () => {
    axios({
      method: "post",
      url: baseUrl + "/api/teacher_list",
      data: {
        school_id: localStorage.getItem("schoolId")
      },
      headers: {
        "Content-Type": "multipart/form-data",
        "Authorization": localStorage.getItem('access_token')
      },
    }).then((response) => {
      console.log(response);
      if (response.data.status === true) {
        setTeachersData(response.data.data);
        console.log(response);
      }
    });
  }


  const [dropdownOpen, setDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);

  const toggleDropdown = () => {
    setDropdownOpen((prev) => !prev);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setDropdownOpen(false);
    }
  };

  useEffect(() => {
    if (dropdownOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dropdownOpen]);
  // 
  // --------------------25-06------------

  const [searchQuery, setSearchQuery] = useState('');
  const filteredPrograms = programs.filter((program) =>
    program.program_name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const initialBorderColor = {
    role: "",
    active: "",
    moveTo: "",
    newProgram: ""
  };

  const [borderColor, setBorderColor] = useState(initialBorderColor);

  const handleFocus = (borderColor) => {
    if (borderColor === "role") {
      setBorderColor({ ...borderColor, role: "#4D6EE3" });
    } else if (borderColor === "active") {
      setBorderColor({ ...borderColor, active: "#4D6EE3" });
    } else if (borderColor === "moveTo") {
      setBorderColor({ ...borderColor, moveTo: "#4D6EE3" });
    } else if (borderColor === "newProgram") {
      setBorderColor({ ...borderColor, newProgram: "#4D6EE3" });
    }
  };

  const handleBlur = () => {
    setBorderColor(initialBorderColor);
  };

  // live
  const [isFocused, setIsFocused] = useState(false);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside1);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside1);
    };
  }, []);

  const handleClickOutside1 = (event) => {
    if (roleInputRef.current && !roleInputRef.current.contains(event.target)) {
      setBorderColor(initialBorderColor);
    }
  };

  // ----------10-07-------------
  /* ----attendance-details------- */
  const [presentCount, setPresentCount] = useState(0);
  const [absentCount, setAbsentCount] = useState(0);
  const [leaveCount, setLeaveCount] = useState(0);
  const [totalDaysCount, setTotalDays] = useState(0);
  const data = {
    datasets: [
      {
        // data: [present, absents, leave, totaldays - (present + absents + leave)], // Values to display
        data: [presentCount, absentCount, leaveCount, totalDaysCount - (presentCount + absentCount + leaveCount)], // Values to display  
        // data: [155, 250 - 155], // Values to display
        backgroundColor: ['#4CAF50', '#F44336', '#FF9800', '#E0E0E0'], // Colors for the segments
        borderWidth: 0,
        hoverBackgroundColor: ['#4CAF50', '#FF9800', '#F44336', '#E0E0E0']
      }
    ]
  };

  const options = {
    rotation: -90, // Start angle in degrees
    circumference: 180, // Sweep angle in degrees
    cutout: '70%', // Inner radius as a percentage of the chart radius
    plugins: {
      tooltip: {
        enabled: true // Disable tooltips
      }
    },
    elements: {
      arc: {
        borderWidth: 0 // No border
      }
    }
  };

  // --------30-09-------------
  const [checkinStatus, setCheckinStatus] = useState(false);
  // ----------------------------

  const fetchAttendanceDetails = async (studentId) => {
    axios({
      method: "post",
      url: baseUrl + "/api/staff_preview",
      data: {
        school_id: localStorage.getItem("schoolId"),
        staff_id: studentId
      },
      headers: {
        "Content-Type": "multipart/form-data",
        "Authorization": localStorage.getItem('access_token')
      },
    }).then((response) => {
      console.log(response);
      if (response.data.status === true) {
        setPresentCount(response.data.present);
        setAbsentCount(response.data.absents);
        setLeaveCount(response.data.leave);
        setTotalDays(response.data.totaldays);
        setCheckinStatus(response.data.checkin_flg == 1 && true);
        console.log(response);
      }
    });
  }

  // -------16-07------------------
  // const [staffDataLength, setStaffDataLength] = useState(23);
  const [staffDataLength, setStaffDataLength] = useState(() => Math.floor(window.innerWidth / 80));


  // 25-07
  const [selectedImage, setSelectedImage] = useState(null);

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    setUser({ ...user, profile_image: file });
    setSelectedImage(file);
  };

  // ------------27-07------------

  const handleInviteAllStaff = () => {
    if (selectedStaff.length !== 0) {
      try {
        axios({
          method: "post",
          url: baseUrl + "/api/staff_invite",
          data: {
            staff_ids: selectedStaff,
            updated_by: localStorage.getItem('loginUserId')
          },
          headers: {
            "Content-Type": "multipart/form-data",
            "Authorization": localStorage.getItem('access_token')
          },
        }).then((response) => {
          console.log(response);
          if (response.data.status === true) {
            // handleInviteClick();
            setselectedStaff([])
            setSnackbarMessage(response.data.message);
            setMessageType('success');
            handleSnackbarOpen();
          } else {
            setSnackbarMessage(response.data.message);
            setMessageType('error');
            handleSnackbarOpen();
          }
        });
      } catch (error) {
        console.log(error)
      }
    }
  }

  // const [showConfetti, setShowConfetti] = useState(false);
  // const { width, height } = useWindowSize();
  // const handleInviteClick = () => {
  //   setShowConfetti(true);
  //   setTimeout(() => setShowConfetti(false), 5000);
  // }

  const [refreshAddStaffForm, setRefreshAddStaffForm] = useState(false);

  // ------------------------10-09--------------------

  const [staffPreviewIndex, setStaffPreviewIndex] = useState(0);

  useEffect(() => {
    $(document).on("click", "#newStaffCreate", function () {
      setRefreshAddStaffForm(true);
      setTimeout(function () {
        setRefreshAddStaffForm(false);
      }, 50);
      setError(null);
      setStudentId(null);
      setValidationErrors({});
      setUser({
        first_name: "",
        email: "",
        phone: "",
        dob: "",
        joining_date: "",
        address: "",
        role_id: "",
        profile_image: null
      });
      setSelectedPermissions([]);
      setSelectedPrograms([]);
      setSelectedImage(null);
      displaySetUser({
        id: "",
        first_name: "",
        email: "",
        phone: "",
        dob: "",
        joining_date: "",
        address: "",
        assign_classes: "",
        role_id: "",
        permissions_id: "",
        profile_image: null,
        staff_color: ""
      });
      $(".overlay").addClass("visible");
      $(".popup-modal-container").addClass("visible");
    });
    $(document).on("click", ".popup-student-details .close", function () {
      $(".popup-student-details").removeClass("visible");
    });
    $(document).on("click", ".sp-btn-cancel", function () {
      setError(null);
      setSelectedImage(null);
      displaySetUser({
        id: "",
        first_name: "",
        email: "",
        phone: "",
        dob: "",
        joining_date: "",
        address: "",
        assign_classes: "",
        role_id: "",
        permissions_id: "",
        profile_image: null,
        staff_color: ""
      });
      $(".overlay").removeClass("visible");
      $(".popup-modal-container").removeClass("visible");
      handleTabUnSelect();
    });
    fetchProgramsData();
    fetchTeachersData();
    fetchRoles();
    fetchPrograms();
    fetchPermissions();
    fetchStaffData();
  }, []);

  return (
    // <CelebrationWrapper>
    <section className="staff_atten_cus">
      <Snackbar className={`snack_total ${messageType === 'success' ? "snackbar_success" : "snackbar_failure"}`}
        anchorOrigin={{ vertical: snackbarState.vertical, horizontal: snackbarState.horizontal }}
        open={snackbarState.open}
        onClose={handleSnackbarClose}
        message={
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <ListItemIcon>
              <div>
                {messageType === 'success' ?
                  <span class="icon-List_Present" style={{ fontSize: " 21px;" }}>
                    <span class="path1"></span>
                    <span class="path2"></span>
                  </span> :
                  <span class="icon-List_Absent" style={{ fontSize: " 21px;" }}>
                    <span class="path1"></span>
                    <span class="path2"></span>
                    <span class="path3"></span>
                    <span class="path4"></span>
                    <span class="path5"></span>
                    <span class="path6"></span>
                  </span>
                }
              </div>
              {/* <img src={messageType === 'success' ? SuccessIcon : ErrorIcon} alt="Success" style={{ width: '24px', height: '24px' }} /> */}
            </ListItemIcon>
            <div className="success_cuc">
              <p> {snackbarMessage} </p>
            </div>
          </div>
        }
        // ContentProps={{
        //     sx: {
        //         border: "1px solid",
        //         opacity: "1",
        //         background: messageType === 'success' ? "#40BE61" : "#FD4954",
        //         borderColor: messageType === 'success' ? "#40BE61" : "#FD4954",
        //         width: 'auto',
        //         minWidth:'auto'
        //     }
        // }}
        autoHideDuration={3000}
      />
      <div className="outer-container">
        <div className="overlay" />
        <div id="left-panel" className="sp-left-panel">
          <CommonSidebarHeader />
          <div className="sp-middle-band">
            <div className="sp-nav y-auto">
              <div className="name_tag_side">
                <h4 className="sp-title">Quick Actions</h4>
                <ul>
                  <li onClick={() => { navigate('/dashboard'); }}>
                    <span>
                      <i className="icon-Dashboard" />
                      <p>Dashboard</p>
                    </span>
                  </li>
                  <li
                    onClick={() => {
                      navigate("/activity");
                    }}
                  >
                    <span>
                      <i className="icon-Activity" />
                      <p>Activity</p>
                    </span>
                  </li>
                  <li
                    onClick={() => {
                      navigate("/calendar");
                    }}
                  >
                    <span>
                      <i className="icon-Timetable" />
                      <p>Calendar</p>
                    </span>
                  </li>
                  <li className="selected">
                    <span>
                      <i className="icon-Staff" />
                      <p>Staff</p>
                    </span>
                  </li>
                  {/* <li>
                    <span>
                      <i className="icon-Message-Outline" />
                      Messages
                    </span>
                  </li>
                  <li>
                    <span>
                      <i className="icon-Fees-Outline" />
                      Fees Management
                    </span>
                  </li>
                  <li>
                    <span>
                      <i className="icon-Enquiries-Outline" />
                      Enquiries
                    </span>
                  </li>
                  <li>
                    <span>
                      <i className="icon-Invites-Outline" />
                      Invites
                    </span>
                  </li> */}
                </ul>
                <h4 className="sp-title flexc justifySp" onClick={() => handleOpenNewProgramPopupVisible("add")}>
                  Programs{" "}
                  <span id="add-programs" className="sp-plus">
                    +
                  </span>
                </h4>
                <ul>
                  {programsLoading &&
                    programsData &&
                    programsData.length > 0 ? (
                    programsData.map((data) => {
                      return (
                        <li onClick={() => {
                          setFilterValue('');
                          localStorage.setItem("programId", data.id);
                          localStorage.setItem("programName", data.program_name);
                          navigate('/student_list');
                        }}>
                            <span
                                  className="ft-400 truncate"
                                  data-tooltip-id={`program-tooltip-${data.id}`}
                                  data-tooltip-content={data.program_name}
                                  data-tooltip-place="bottom"
                                >
                                  <i className="icon-Program_1" />
                                  <p>
                                    {
                                      (data.program_name && data.program_name.length <= 25) ? data.program_name : `${data.program_name.slice(0, 25)}...`
                                    }
                                  </p>
                            </span>
                                {data.program_name.length > 25 && <Tooltip id={`program-tooltip-${data.id}`} />}
                        </li>
                      );
                    })
                  ) : (
                    <div className="data-loader extracss_load">
                      {/* <Loader
                      type="spinner-cub"
                      bgColor="#4D6EE3"
                      color="#6B6F8C"
                      title="Loading..."
                      size={35}
                    /> */}
                      <WindmillSpinner loading={"loading"} color="#4d6ee3d6" size={30} />
                      <p>Loading...</p>
                    </div>
                  )}
                </ul>
              </div>
            </div>
            <CommonSidebarFooter />
          </div>
        </div>
        <div className="sp-right-panel">
          <div className="sp-top-bar flexc justifySp top_bar_cus">
            <div className="ft-wt600 flexc pad_ic_ri">
              <span className="selected-icon">
                <i className="icon-Staff" />
              </span>
              <p className="program_tit">Staff</p>
              {/* <i className="icon-More-Icon" /> */}
            </div>
            <div className="flexc">
              <div className="file-input-container">
                <label
                  className="sp-view-btn ft-wt500 flexc"
                  //   htmlFor="fileInput"
                  onClick={() => {
                    importStudents1();
                  }}
                >
                  <i className="icon-Import-Outline" />
                  Import
                </label>
              </div>
              <button className="createN ft-wt500 mr-lf10 space_icon_pad" id="newStaffCreate">
                <i class="fa-solid fa-plus"></i> New Staff
              </button>
            </div>
          </div>
          <div className="sp-header bor_bot_stucus">
            <nav className="flexc">
              {/* <div class="sp-list-view flexc hidden"><i class="icon-Attandance"></i>Student List</div> */}
              <div className="sp-list-view flexc selected first_list_mar_cus">
                <i className="icon-Student-List" />
                Staff List
              </div>
              <div
                className="sp-list-view flexc"
                onClick={() => {
                  navigate("/staff_attendance");
                }}
              >
                <i className="icon-Attandance" />
                Attendance
              </div>
            </nav>
            <>
              {!importstatus && (
                <div className="sp-menu-bar flexc justifySp extra_pad_men drop_list_full">
                  <div className="sp-menugroup">
                    <div className="flexc">
                      {selectedStaff.length === 0 ? (
                        <>
                          <div className="drop_move_cus"
                            style={{
                              width: "100%", height: "40px",
                              borderColor: borderColor.active,
                            }}
                            onFocus={() => handleFocus("active")}
                            onBlur={handleBlur}
                          >
                            <Dropdown
                              label={
                                <span style={{ color: "#1B252F", fontSize: "0.8825em" }}>
                                  {activeStatus === 1 ? "Active Staff" : "Inactive Staff"}
                                </span>
                              }
                              style={{ width: "100%" }}
                            >
                              <Dropdown.Item
                                className={`${activeStatus === 1 ? "selected-item dd_select pad_progr" : "pad_progr"}`}
                                icon={activeStatus === 1 ? HiCheck : null}
                                onClick={() => { setFilterValue(""); handleActiveClick(1) }}
                              >
                                Active Staff
                              </Dropdown.Item>
                              <Dropdown.Item
                                className={`${activeStatus === 0 ? "selected-item dd_select pad_progr" : "pad_progr"}`}
                                icon={activeStatus === 0 ? HiCheck : null}
                                onClick={() => { setFilterValue(""); handleActiveClick(0) }}
                              >
                                Inactive Staff
                              </Dropdown.Item>
                            </Dropdown>

                          </div>
                          <div className={`btn-search-bar flexc glo-showdow ${isFocused ? 'focused' : ''}`}>
                            <i className="icon-Search" />
                            {/* 31-07-2024 */}
                            <input
                              type="text"
                              id="simple-search"
                              placeholder="Search Staff"
                              value={filterValue}
                              onChange={handleInputChange}
                              onFocus={() => setIsFocused(true)}
                              onBlur={() => setIsFocused(false)}
                              className="search-input"
                            />
                            {/* 31-07-2024 */}
                            <i onClick={clearInput} className={`icon-Close ${filterValue ? '' : 'hide-visibility'}`} />
                          </div>
                          {/* <div className={`btn-search-bar flexc glo-showdow ${isFocused ? 'focused' : ''}`}>
                              <i className="icon-Search" />
                              <input
                                type="search"
                                placeholder="Search Staff"
                                value={filterValue}
                                onChange={handleInputChange}
                                onFocus={() => setIsFocused(true)}
                                onBlur={() => setIsFocused(false)}
                                className="search-input"
                              />
                            </div> */}
                        </>
                      ) : (
                        <div style={{ display: "flex" }}>
                          <div className="drop_move_cus"
                            style={{
                              width: "100%", height: "40px",
                              borderColor: borderColor.moveTo
                            }}
                            onFocus={() => handleFocus("moveTo")}
                            onBlur={handleBlur}
                          >
                            <Dropdown
                              label={
                                <span
                                  style={{ color: "#1b252f", fontSize: "0.8825em", justifyContent: "flex-start" }}
                                >
                                  <i className="fa-solid fa-arrow-right-to-bracket"></i> Move to
                                </span>
                              }
                              style={{ width: "100%" }}
                            >
                              <Dropdown.Item onClick={handleNewProgramClick} className="pad_progr">
                                <i className="fa-solid fa-arrows-rotate fa-flip-horizontal"></i> New Program
                              </Dropdown.Item>
                              {activeStatus === 1 && (
                                <Dropdown.Item onClick={handleRecycleBinClick} className="pad_progr">
                                  <i className="fa-regular fa-trash-can"></i> Recycle Bin
                                </Dropdown.Item>)
                              }
                            </Dropdown>
                          </div>
                          {activeStatus === 1 && (
                            <div className="invite_cus_div">
                              <button className="sp_mbtn flexc" onClick={() => handleInviteAllStaff()}>
                                <i className="icon-Invite_Parents" />
                                <span>Invite all Staff</span>
                              </button>
                            </div>
                          )}
                        </div>
                        // <div className="drop_move_cus"
                        //   style={{
                        //     width: "100%",
                        //     borderColor: borderColor.moveTo,
                        //   }}
                        //   onFocus={() => handleFocus("moveTo")}
                        //   onBlur={handleBlur}
                        // >
                        //   <Dropdown
                        //     label={
                        //       <span
                        //         style={{ color: "#707070", fontSize: "0.8825em", justifyContent: "flex-start" }}
                        //       >
                        //         <i className="fa-solid fa-arrow-right-to-bracket"></i> Move to
                        //       </span>
                        //     }
                        //     style={{ width: "100%", padding: "6px 0px" }}
                        //   >
                        //     <Dropdown.Item
                        //       onClick={handleNewProgramClick}
                        //       className="pad_progr"
                        //     >
                        //       <i className="fa-solid fa-arrows-rotate fa-flip-horizontal"></i> New Program
                        //     </Dropdown.Item>
                        //     <Dropdown.Item onClick={handleRecycleBinClick} className="pad_progr">
                        //       <i className="fa-regular fa-trash-can"></i> Recycle Bin
                        //     </Dropdown.Item>
                        //   </Dropdown>
                        // </div>
                      )}
                    </div>
                  </div>

                </div>
              )}
            </>
          </div>
          <div className="load-container">
            <div className="sp-table-load overxy-auto">
              <div className="sp-table-container">
                {loading && staffData && staffData.length < 0 ? (
                  <ImportComponent
                    importStudent={"staff"}
                    handleImportStatus={handleImportStatus}
                    setProgress={setProgress} setIsLoading={setIsLoading}
                  />
                ) : importstatus ? (
                  <ImportComponent
                    importStudent={"staff"}
                    handleImportStatus={handleImportStatus}
                    setProgress={setProgress} setIsLoading={setIsLoading}
                  />
                ) : (
                  <>
                    {loading && staffData && staffData.length > 0 && <div className="sp-table-header flexc  costomize_gap_div extra_pad_men" style={{ paddingRight: "13px" }}>
                      {staffData.length !== 0 && <div className="table_input_sty">
                        {staffData.length !== 0 &&
                          <input
                            type="checkbox"
                            id="check-all"
                            disabled={staffData.length===1}
                            onChange={handleSelectAll}
                            checked={selectedStaff.length === staffData.length-1&&staffData.length>1}
                          />
                        }
                        {/* <input type="checkbox" id="check-all" /> */}
                      </div>}
                      {/* <div className="checkbox">
                        <i className="un-check icon-sp-present" />
                      </div> */}
                      <div className="sp-title flex-2 gap-after">
                        <span>Staff Name</span>
                      </div>
                      <div className="sp-title flex-2">
                        <span>Email</span>
                      </div>
                      <div className="sp-title flex-1">
                        <span>Phone Number</span>
                      </div>
                      <div className="sp-title flex-1">
                        <span>Role</span>
                      </div>
                      <div className="sp-title flex-1">
                        <span>Program</span>
                      </div>
                      <div className="sp-title flex-1">
                        <span>Joining Date</span>
                      </div>
                      <div className="sp-title flex-1">
                        <span>Date of Birth</span>
                      </div>
                    </div>}
                    <div className={`sp-table-body`} style={{ overflowX: "auto" }}>
                      {loading && staffData && staffData.length > 0 ? (
                        staffData.map((student, index) => (
                          <div
                            className="select_field_point"
                            style={{ cursor: "pointer" }}
                            onClick={(event) => handleTabSelect(event)}
                          >
                            <div className="row flexc costomize_gap_div extra_side_padcus">
                              <div className="table_input_sty">
                                <input
                                  type="checkbox"
                                  className="sub_select"
                                  disabled={student?.is_main_admin===1}
                                  onChange={() => handleSelectStudent(student.id)}
                                  checked={selectedStaff.includes(student.id)}
                                />
                                {/* <input type="checkbox" className="sub_select" /> */}
                              </div>
                              {/* <div class="checkbox">
                                <i class="un-check icon-Tick"></i>
                              </div> */}
                              <div
                                className="sp-td-content flex-2 truncate click-popup text-cap"
                                onClick={() => {
                                  openStudentPopup(student, index);
                                }}
                              >
                                <span className="ft-500 flexc truncate img_wid_dis">

                                  {
                                    student?.profile_image !== null ?
                                      <img
                                        src={baseUrl + "/api/" + student?.profile_image}
                                        width={40}
                                        alt={student?.first_name}
                                        style={{ borderRadius: "5px" }}
                                      /> : <LogoComponent name={student?.first_name} index={index} bgColor={student?.staff_color} />
                                  }
                                  <span
                                    className="mr-lf10 truncate name_bold"
                                    data-tooltip-id={`name-tooltip-${student?.id}`}
                                    data-tooltip-content={student?.first_name}
                                    data-tooltip-place="bottom"
                                  >
                                    {
                                      (student?.first_name && student?.first_name.length <= staffDataLength) ? student?.first_name : `${student?.first_name.slice(0, staffDataLength)}...`
                                    }
                                  </span>
                                  {
                                    student?.first_name.length > staffDataLength &&
                                    <Tooltip id={`name-tooltip-${student?.id}`} />
                                  }
                                  {/* <Tooltip id={`name-tooltip-${student?.id}`} /> */}
                                </span>
                              </div>
                              <div
                                className="sp-td-content flex-2 truncate click-popup"
                                onClick={() => {
                                  openStudentPopup(student, index);
                                }}
                              >
                                <span
                                  className="ft-400 truncate"
                                  data-tooltip-id={`email-tooltip-${student?.id}`}
                                  data-tooltip-content={student?.email}
                                  data-tooltip-place="bottom"
                                >
                                  {
                                    (student?.email !== null && student?.email !== "") ?
                                      ((student?.email && student?.email.length <= staffDataLength) ?
                                        student?.email : `${student?.email.slice(0, staffDataLength)}...`
                                      )
                                      : "--"
                                  }

                                </span>
                                {
                                  (student?.email !== null && student?.email !== "") && student?.email.length > staffDataLength && <Tooltip id={`email-tooltip-${student?.id}`} />
                                }
                              </div>
                              <div
                                className="sp-td-content flex-1 click-popup text-cap"
                                onClick={() => {
                                  openStudentPopup(student, index);
                                }}
                              >                             
                                <span className="ft-400">{student?.phone}</span>
                              </div>
                              <div
                                className="sp-td-content flex-1 click-popup text-cap"
                                onClick={() => {
                                  openStudentPopup(student, index);
                                }}
                              >
                                <span className="ft-400 truncate click-popup text-cap">
                                  {
                                    // student?.role_id
                                    roles.map((role) =>
                                      role.id === parseInt(student?.role_id)
                                        ? role.role_name
                                        : null
                                    )
                                  }
                                </span>
                              </div>
                              <div
                                className="sp-td-content flex-1 but_update_div truncate click-popup text-cap"
                                onClick={() => {
                                  openStudentPopup(student, index);
                                }}
                              >
                                {/* <span className="ft-400">{student?.assign_classes}</span>  */}
                                <span
                                  className="ft-400"
                                  data-tooltip-id={`program-tooltip-${student?.id}`}
                                  data-tooltip-content={
                                    student?.assign_classes && (student?.assign_classes
                                      .split(",")
                                      .map(subProgram =>
                                        programs.find(program => subProgram.trim() === program.id.toString())
                                      )
                                      .filter(program => program !== undefined)
                                      .map(program => program.program_name)
                                      .join(", ")
                                    )}

                                  data-tooltip-place="bottom"
                                >
                                  {student?.assign_classes ? (
                                    student.assign_classes.split(",").length <= 2 ? (
                                      student.assign_classes
                                        .split(",").map((subProgram) =>
                                          programs.map((program) =>
                                            subProgram.trim() === program.id.toString() ? (
                                              <button
                                                key={program.id}
                                                className="last_div sp-selected-bt ft-wt600 ft-13 pro_staf_name"
                                              // style={{ margin: "5px" }}
                                              >
                                                <p className="pro_staf_name">{program.program_name}</p>
                                              </button>
                                            ) : null
                                          )
                                        )
                                    ) : (
                                      student.assign_classes
                                        .split(",").map((subProgram) =>
                                          programs.map((program) =>
                                            subProgram.trim() === program.id.toString() ? (
                                              <button
                                                key={program.id}
                                                className="last_div sp-selected-bt ft-wt600 ft-13 pro_staf_name"
                                              // style={{ margin: "5px" }}
                                              >
                                                <p className="pro_staf_name">{program.program_name}</p>
                                              </button>
                                            ) : null
                                          )
                                        )
                                      // `${(student?.assign_classes.split(",").slice(0, 2).map(subProgram =>
                                      //     programs.find(program => subProgram.trim() === program.id.toString())
                                      //   )
                                      //   .filter(program => program !== undefined)
                                      //   .map(program => program.program_name)
                                      //   .join(", ")
                                      // )}...`
                                    )
                                  ) : (
                                    "--"
                                  )}
                                </span>
                                {
                                  student?.assign_classes && student.assign_classes.split(",").length > 2 && <Tooltip id={`program-tooltip-${student?.id}`} />
                                }
                                {/* {
                                  (student?.assign_classes
                                    .split(",")
                                    .map(subProgram =>
                                      programs.find(program => subProgram.trim() === program.id.toString())
                                    )
                                    .filter(program => program !== undefined)
                                    .map(program => program.program_name)
                                    .join(", ")
                                  ).length > staffDataLength && <Tooltip id={`program-tooltip-${student?.id}`} />
                                } */}
                              </div>
                              <div
                                className="sp-td-content flex-1 click-popup text-cap"
                                onClick={() => {
                                  openStudentPopup(student, index);
                                }}
                              >
                                <span className="ft-400">
                                  {(student?.joining_date !== null && student?.joining_date !== "") ? convertDBDateFormat(String(student?.joining_date).split("T")[0]) : "--"}
                                </span>
                              </div>
                              <div
                                className="sp-td-content flex-1 click-popup text-cap"
                                onClick={() => {
                                  openStudentPopup(student, index);
                                }}
                              >
                                <span className="ft-400">
                                  {(student?.dob !== null && student?.dob !== "") ? convertDBDateFormat(String(student?.dob).split("T")[0]) : "--"}
                                </span>
                              </div>
                            </div>
                          </div>
                        ))
                      ) : staffData.length == 0 ? (
                        <Nodata contentType={filterValue === "" ? "staffList" : "staffSearch"} />
                        // <center>
                        //   <div className="sp-title flex-3 nodata_content_style">
                        //     <img src={nodata} />
                        //     <p>Data Not Found</p>
                        //   </div>
                        // </center>
                      ) : (
                        <div className="data-loader extracss_load">
                          {/* <Loader
                          type="spinner-cub"
                          bgColor="#4D6EE3"
                          color="#6B6F8C"
                          title="Loading..."
                          size={35}
                        /> */}
                          <WindmillSpinner loading={"loading"} color="#4d6ee3d6" size={30} />
                          <p>Loading...</p>
                        </div>
                      )}
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
        {/*  Create NewStaff  */}
        <div className="popup-modal-container">
          <div className="popup-wrap">
            {studentId !== null ? (
              <h2 className="ft-wt700">Update Staff</h2>
            ) : (
              <h2 className="ft-wt700 ">Create New Staff</h2>
            )}
            {
              refreshAddStaffForm === false ?
                <div className="sp-pop-header pt-0" >
                  {/* <h3 className="ft-wt600">Staff Details</h3> */}
                  <div className="form-section-default flexc justifySp fwrap">
                    <div className="field-label half-field error_mes_cus pos_stu_cus mt-12">
                      <h3 className="ft-wt600">Roles &amp; Permissions</h3>
                      <div className="roll_mar_top">
                        <label>Role<span className="col_red">*</span></label>
                        <div
                          className="field-value-holder dropbutton"
                          ref={roleInputRef}
                          style={{
                            width: "100%",
                            borderColor: validationErrors.role_id ? "red" : borderColor.role,
                          }}
                          onKeyDown={(event) =>
                            handleKeyPress(event, submitButtonRef)
                          }
                          onFocus={() => handleFocus("role")}
                          onBlur={handleBlur}
                          tabIndex="0"
                        >
                          <Dropdown
                            label={
                              <span
                                style={{
                                  fontSize: "0.8825em",
                                  color: selectedRoles ? '#1B252F' : '#292929',
                                  justifyContent: "flex-start"
                                }}
                              >
                                {selectedRoles ? selectedRoles.role_name : "Select Role"}
                              </span>
                            }
                            style={{ width: "100%" }}
                          >
                            {roles.map((item) => (
                              <Dropdown.Item
                                key={item.id}
                                className={`${selectedRoles && selectedRoles.id === item.id ? "selected-item dd_select" : ""
                                  }`}
                                icon={selectedRoles && selectedRoles.id === item.id ? HiCheck : null}
                                onClick={() => { setUser({ ...user, role_id: item.id }); setSelectedPrograms([]) }}
                              >
                                {item.role_name}
                              </Dropdown.Item>
                            ))}

                          </Dropdown>
                        </div>
                        {validationErrors.role_id && (
                          <p className="error pos_abo_stu">{validationErrors.role_id}</p>
                        )}
                      </div>

                    </div>
                    <div className="full-field">
                      {selectedRoles && (
                        <div className="field-label mar_top_roll full-field error_mes_cus">
                          {selectedRoles.role_name === "Administrator" && (
                            <div>
                              <label>Permissions</label>
                              <div className="label-permissions mar_ful_permison">
                                <button className="sp-selected-bt ft-wt600 ft-13 ">Manage Student & Staff</button>
                                <button className="sp-selected-bt ft-wt600 ft-13 ">Full Access to all features</button>
                                <button className="sp-selected-bt ft-wt600 ft-13 ">Manage Program</button>
                                <button className="sp-selected-bt ft-wt600 ft-13 ">Manage Fees</button>
                                <button className="sp-selected-bt ft-wt600 ft-13 ">Admin Control</button>
                              </div>
                            </div>
                          )}
                          {selectedRoles.role_name === "Teacher" && (
                            <div>
                              <label>Permissions</label>
                              <div className="label-permissions mar_ful_permison">
                                <button className="sp-selected-bt ft-wt600 ft-13 ">Manage Student</button>
                                <button className="sp-selected-bt ft-wt600 ft-13 ">Full Access to all features</button>
                              </div>
                            </div>
                          )}
                          {selectedRoles.role_name === "Security" && (
                            <div>
                              <label>Permissions</label>
                              <div className="label-permissions mar_ful_permison">
                                <button className="sp-selected-bt ft-wt600 ft-13 ">Scan QR</button>
                                <button className="sp-selected-bt ft-wt600 ft-13 ">Add Visitor</button>
                              </div>
                            </div>
                          )}
                          {selectedRoles.role_name === "Driver" && (
                            <div>
                              <label>Permissions</label>
                              <div className="label-permissions mar_ful_permison">
                                <button className="sp-selected-bt ft-wt600 ft-13 ">Pickup Detail</button>
                              </div>
                            </div>
                          )}
                          {selectedRoles.role_name === "Helper" && (
                            <div>
                              <label>Helper have below permissions</label>
                              <div className="label-permissions mar_ful_permison">
                                <button className="sp-selected-bt ft-wt600 ft-13 ">Scan QR</button>
                                <button className="sp-selected-bt ft-wt600 ft-13">Pickup Details</button>
                              </div>
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                    <div className="field-label half-field error_mes_cus pos_stu_cus">
                      <label>Staff Name<span className="col_red">*</span><span></span></label>
                      <div className="">
                        <input
                          ref={firstNameInputRef}
                          type="text"
                          value={user.first_name}
                          onChange={(ev) =>
                            setUser({ ...user, first_name: ev.target.value })
                          }
                          onKeyPress={(event) =>
                            handleKeyPress(event, programsInputRef)
                          }
                          className={`field-value-holder custamize_color_place extra_bor_clr sp-field-input ${validationErrors.first_name ? 'error' : ''}`}
                          placeholder="Enter full name"
                        />
                      </div>
                      {validationErrors.first_name && (
                        <p className="error pos_abo_stu">{validationErrors.first_name}</p>
                      )}
                    </div>
                    <div className="field-label half-field error_mes_cus cus_pro but_foc_cus pos_stu_cus">
                      <label>Programs{selectedRoles && selectedRoles.id === 1 && <span className="col_red">*</span>}</label>
                      <div className={`field-value-holder`} style={{ position: 'relative', borderColor: validationErrors.programs ? "red" : "#d9dce6" }}>
                        <button
                          id="dropdownSearchButton"
                          ref={programsInputRef}
                          className={`${validationErrors.programs ? 'error' : ''}`}
                          onClick={toggleDropdown}
                          onKeyPress={(event) => handleKeyPress(event, pnoneInputRef)}
                          type="button"
                          disabled={selectedRoles && selectedRoles.id === 1 ? false : true}
                          style={{
                            position: "relative",
                            width: '100%',
                            textAlign: "start",
                            height: "40px",
                            fontSize: "13px",
                            color: "#292929",
                            padding: selectedPrograms.length !== 0 ? "0px" : "7px 0.75rem"
                          }}
                        >
                          <div className="dis_pro_flex">
                            <div className="pro_wid_style">
                              {selectedPrograms.length !== 0
                                ? selectedPrograms.slice(0, 3).map((selectedId) => {
                                  const program = programs.find((p) => p.id.toString() === selectedId);
                                  if (program) {
                                    return (
                                      <button
                                        key={program.id}
                                        className="sp-selected-bt ft-wt600 ft-13 mr-2 truncate"
                                        style={{ margin: "5px" }}
                                        onClick={(e) => e.stopPropagation()}
                                      >
                                        {program.program_name}
                                      </button>
                                    );
                                  }
                                  return null;
                                })
                                : "Selected Programs"
                              }
                            </div>
                            {
                              selectedPrograms.length > 3 && <div><p className="pro_more_text">{`+${selectedPrograms.length - 3} more`}</p></div>
                            }
                          </div>
                          <div className="drop_ic_cus">
                            <i class="fa-solid fa-chevron-down"></i>
                          </div>
                        </button>
                        {dropdownOpen && (
                          <div id="dropdownSearch"
                            ref={dropdownRef}
                            style={{ position: 'absolute', zIndex: '999', backgroundColor: 'white', width: '100%' }}>
                            <ul className=" px-3 pb-3 overflow-y-auto text-sm text-gray-700 dark:text-gray-200 back_white_cus" aria-labelledby="dropdownSearchButton">
                              <div className="field-value-holder custamize_color_place" style={{ position: 'relative', width: '100%', marginTop: "0px" }}>
                                <i
                                  className="icon-Search"
                                  style={{
                                    position: 'absolute',
                                    left: '10px',
                                    top: '50%',
                                    transform: 'translateY(-50%)',
                                    pointerEvents: 'none',
                                  }}
                                ></i>
                                <input
                                  style={{ width: '100%', height: '40px', paddingLeft: '35px' }}
                                  type="search"
                                  id="input-group-search"
                                  value={searchQuery}
                                  onChange={(e) => setSearchQuery(e.target.value)}
                                  placeholder="Search"
                                />
                              </div>
                              {filteredPrograms.length !== 0 ? filteredPrograms.map((item, index) => (
                                <li key={item.id}>
                                  <div className={selectedPrograms.includes(item.id.toString()) ? "program_checked flex items-center pad_15 rounded hover:bg-gray-100 dark:hover:bg-gray-600" : "flex items-center pad_15 rounded hover:bg-gray-100 dark:hover:bg-gray-600"}>
                                    <input
                                      id={`checkbox-item-${item.id}`}
                                      type="checkbox"
                                      checked={selectedPrograms.includes(item.id.toString())}
                                      onChange={(e) => handleProgramsChange(item.id.toString(), e.target.checked)}
                                    //className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
                                    />                               
                                    <label
                                     htmlFor={`checkbox-item-${item.id}`} 
                                     className="w-full ml-2 text-sm font-medium text-gray-900 rounded dark:text-gray-300"
                                     data-tooltip-id={`program-tooltip-${item.id}`}
                                     data-tooltip-content={item.program_name && item.program_name}
                                     data-tooltip-place="bottom"
                                     >
                                                                          {
                                      (item.program_name && item.program_name.length <= 25) ? item.program_name : `${item.program_name.slice(0, 25)}...`
                                    }
                                      {/* {item.program_name} */}
                                      {item.program_name && item.program_name.length > 25 && <Tooltip id={`program-tooltip-${item.id}`} />}
                                    </label>
                                  </div>
                                </li>
                              )) : <li className="nodata_text_style">Data Not Found</li>}
                            </ul>
                          </div>
                        )}
                      </div>
                      {validationErrors.programs && (
                        <p className="error pos_abo_stu">{validationErrors.programs}</p>
                      )}
                    </div>
                    <div className="field-label half-field error_mes_cus pos_stu_cus">
                      <label>Phone Number<span className="col_red">*</span></label>
                      <div className="">
                        <input
                          ref={pnoneInputRef}
                          type="text"
                          value={user.phone}
                          onChange={(ev) =>
                            setUser({ ...user, phone: ev.target.value })
                          }
                          onKeyPress={(event) => handleKeyPress(event, emailInputRef)}
                          className={`field-value-holder custamize_color_place extra_bor_clr sp-field-input ${validationErrors.phone ? 'error' : ''}`}
                          placeholder="Phone Number"
                        />
                      </div>
                      {validationErrors.phone && (
                        <p className="error pos_abo_stu">{validationErrors.phone}</p>
                      )}
                    </div>
                    <div className="field-label half-field error_mes_cus pos_stu_cus">
                      <label>Email</label>
                      <div className="">
                        <input
                          ref={emailInputRef}
                          type="text"
                          value={user.email}
                          onChange={(ev) =>
                            setUser({ ...user, email: ev.target.value })
                          }
                          onKeyPress={(event) =>
                            handleKeyPress(event, adressInputRef)
                          }
                          className={`field-value-holder custamize_color_place extra_bor_clr sp-field-input ${validationErrors.email ? 'error' : ''}`}
                          placeholder="Enter your email"
                        />
                      </div>
                      {validationErrors.email && (
                        <p className="error pos_abo_stu">{validationErrors.email}</p>
                      )}
                    </div>
                    <div className="field-label half-field error_mes_cus date_pic_img">
                      <label>Date of Birth</label>
                      <div className="field-value-holder date_iconpos_rel">
                        <DatePicker
                          // ref={dobInputRef}
                          placeholder="DD/MM/YYYY"
                          format={dateFormatList}
                          onChange={ondobChange}
                          value={user.dob ? dayjs(user.dob, dateFormatList) : null}
                        ></DatePicker>
                          <i class="icon-Timetable"></i>
                      </div>
                      {validationErrors.dob && (
                        <p className="error">{validationErrors.dob}</p>
                      )}
                    </div>
                    <div className="field-label half-field error_mes_cus date_pic_img pos_stu_cus ">
                      <label>Joining Date</label>
                      <div className="field-value-holder date_iconpos_rel">
                        <DatePicker
                          // ref={joingInputRef}
                          placeholder="DD/MM/YYYY"
                          format={dateFormatList}
                          onChange={ondojChange}
                          value={user.joining_date ? dayjs(user.joining_date, dateFormatList) : null}
                        ></DatePicker>
                          <i class="icon-Timetable"></i>
                      </div>

                      {validationErrors.joining_date && (
                        <p className="error pos_abo_stu">
                          {validationErrors.joining_date}
                        </p>
                      )}
                    
                    </div>
                    <div className="field-label half-field error_mes_cus ">
                      <label>Staff Photo</label>
                      <div className="field-value-holder">
                        <span className="sp-upload-btn flexc justifySp" style={{
                          color: " #A9AFC3",
                          fontWeight: "500",
                          fontSize: "13px"
                        }}>
                          {selectedImage === null ? (
                            (displayUser.profile_image === null || displayUser.profile_image === "") ? "Upload" :
                              <p style={{
                                color: " #1B252F",
                                fontWeight: "600",
                                fontSize: "13px"
                              }} className="truncate"> {displayUser.profile_image}</p>
                            // "Upload"
                          ) : (
                            <p style={{
                              color: " #1B252F",
                              fontWeight: "600",
                              fontSize: "13px"
                            }} className="truncate"> {selectedImage.name}</p>
                          )}
                          <button className="sp-file-browse">Browse</button>
                        </span>
                        <input
                          className="input-placeholder1"
                          style={{
                            cursor: "pointer"
                          }}
                          // ref={imageInputRef}
                          type="file"
                          accept="image/*"
                          onChange={handleImageChange}
                          onKeyPress={(event) =>
                            handleKeyPress(event, joingInputRef)
                          }
                        />
                      </div>
                      {/* {validationErrors.profile_image && (
                    <p className="error">
                      {validationErrors.profile_image}
                    </p>
                  )} */}
                    </div>
                    <div className="field-label full-field error_mes_cus pos_stu_cus">
                      <label>Address<span className="col_red">*</span></label>
                      <div className="">
                        <input
                          ref={adressInputRef}
                          type="text"
                          value={user.address}
                          onChange={(ev) =>
                            setUser({ ...user, address: ev.target.value })
                          }
                          onKeyPress={(event) =>
                            handleKeyPress(event, roleInputRef)
                          }
                          className={`field-value-holder custamize_color_place extra_bor_clr sp-field-input ${validationErrors.address ? 'error' : ''}`}
                          placeholder="Address"
                        // defaultValue={""}
                        />
                      </div>
                      {validationErrors.address && (
                        <p className="error pos_abo_stu">{validationErrors.address}</p>
                      )}
                    </div>
                  </div>
                  {/* <h3 className="ft-wt600 mt-30">Roles &amp; Permissions</h3>
              <div className="field-label half-field error_mes_cus pos_stu_cus">
                <label>Role<span className="col_red">*</span></label>
                <div
                  className="field-value-holder dropbutton"
                  ref={roleInputRef}
                  style={{
                    width: "100%",
                    borderColor: validationErrors.role_id ? "red" : borderColor.role,
                  }}
                  onKeyDown={(event) =>
                    handleKeyPress(event, submitButtonRef)
                  }
                  onFocus={() => handleFocus("role")}
                  onBlur={handleBlur}
                  tabIndex="0"
                >
                  <Dropdown
                    label={
                      <span
                        style={{
                          fontSize: "0.8825em",
                          color: selectedRoles ? '#1B252F' : '#292929',
                          justifyContent: "flex-start"
                        }}
                      >
                        {selectedRoles ? selectedRoles.role_name : "Select Role"}
                      </span>
                    }
                    style={{ width: "100%" }}
                  >
                    {roles.map((item) => (
                      <Dropdown.Item
                        key={item.id}
                        className={`${selectedRoles && selectedRoles.id === item.id ? "selected-item dd_select" : ""
                          }`}
                        icon={selectedRoles && selectedRoles.id === item.id ? HiCheck : null}
                        onClick={() => setUser({ ...user, role_id: item.id })}
                      >
                        {item.role_name}
                      </Dropdown.Item>
                    ))}

                  </Dropdown>
                </div>
                {validationErrors.role_id && (
                  <p className="error pos_abo_stu">{validationErrors.role_id}</p>
                )}
              </div>
              {selectedRoles && (
                <div className="field-label full-field error_mes_cus">
                  {selectedRoles.role_name === "Administrator" && (
                    <div>
                      <label>Admin have below permissions</label>
                      <div className="label-permissions mt-3">
                        <button className="sp-selected-bt ft-wt600 ft-13 mr-2">Manage Student & Staff</button>
                        <button className="sp-selected-bt ft-wt600 ft-13 mr-2">Full Access to all features</button>
                        <button className="sp-selected-bt ft-wt600 ft-13 mr-2">Manage Program</button>
                        <button className="sp-selected-bt ft-wt600 ft-13 mr-2">Manage Fees</button>
                        <button className="sp-selected-bt ft-wt600 ft-13 mr-2">Admin Control</button>
                      </div>
                    </div>
                  )}
                  {selectedRoles.role_name === "Teacher" && (
                    <div>
                      <label>Teacher have below permissions</label>
                      <div className="label-permissions mt-3">
                        <button className="sp-selected-bt ft-wt600 ft-13 mr-2">Manage Student</button>
                        <button className="sp-selected-bt ft-wt600 ft-13 mr-2">Full Access to all features</button>
                      </div>
                    </div>
                  )}
                  {selectedRoles.role_name === "Security" && (
                    <div>
                      <label>Security have below permissions</label>
                      <div className="label-permissions mt-3">
                        <button className="sp-selected-bt ft-wt600 ft-13 mr-2">Scan QR</button>
                        <button className="sp-selected-bt ft-wt600 ft-13 mr-2">Add Visitor</button>
                      </div>
                    </div>
                  )}
                  {selectedRoles.role_name === "Driver" && (
                    <div>
                      <label>Driver have below permissions</label>
                      <div className="label-permissions mt-3">
                        <button className="sp-selected-bt ft-wt600 ft-13 mr-2">Pickup Detail</button>
                      </div>
                    </div>
                  )}
                  {selectedRoles.role_name === "Helper" && (
                    <div>
                      <label>Helper have below permissions</label>
                      <div className="label-permissions mt-3">
                        <button className="sp-selected-bt ft-wt600 ft-13 mr-2">Scan QR</button>
                        <button className="sp-selected-bt ft-wt600 ft-13 mr-2">Pickup Details</button>
                      </div>
                    </div>
                  )}
                </div>
              )} */}
                </div>
                : <div className="sp-pop-header" ></div>
            }

          </div>
          <div className="sp-bottom-sticky">
            {studentId === null ? (
              <button className="sp-btn-create" ref={submitButtonRef} onClick={onSubmit}>
                Create
              </button>
            ) : (
              <button className="sp-btn-create" onClick={updateUser}>
                Update
              </button>
            )}
            <button className="sp-btn-cancel">Cancel</button>
          </div>
        </div>
        {/* Creating afterPopup */}
        {
          refreshAddStaffForm === false ?
            <div className="popup-student-details">
              <span className="icon-Close close" />
              <div className="student-details-page">
                <div className="sp-top-details flexc">
                  <div className="sp-profile-head">

                    {
                      displayUser.profile_image !== null ?
                        <img
                          src={baseUrl + "/api/" + displayUser.profile_image}
                          alt={displayUser.first_name}
                          width={62}
                          height={62}
                          style={{ borderRadius: '30%' }}
                        /> : <PreviewLogoComponent name={displayUser.first_name} index={staffPreviewIndex} style={"preview-logo-component"} bgColor={displayUser.staff_color} />
                    }
                    <span className="flexc margin_20p">
                      <em className="ft-wt600 ft-18 text-cap">{displayUser.first_name}</em>
                      {checkinStatus && <label className="sp-status">check In</label>}
                    </span>
                  </div>
                  <div className="sp-profile-rt flexc">
                    {/* <span className="sp-accbox icon-Message-Outline flexc" />{" "} */}
                    <span
                      className="sp-accbox mr-lf10 flexc sp-accbox_edit_icon"
                      style={{ borderRadius: "50%" }}
                      onClick={() => {
                        openStudentUpdatePopup(displayUser);
                      }}
                    ><span class="icon-Student_Edit"></span>
                    </span>
                  </div>
                </div>
                <div className="sp-details-box">
                  <div className="sp-tt ft-14 flexc">
                    <i className="icon-Invites-Outline" />
                    Staff Details
                  </div>
                  <div className="form-section-default flexc justifySp fwrap">
                    <div className="field-label half-field">
                      <label className="label-title">Staff Name</label>
                      <div className="label-filled text-cap">{displayUser.first_name}</div>
                    </div>
                    <div className="field-label half-field truncate email_captial">
                      <label className="label-title">Email ID</label>
                      <div className="label-filled truncate"
                        data-tooltip-id={`name-tooltip-${displayUser.id}`}
                        data-tooltip-content={displayUser.email}
                        data-tooltip-place="bottom">
                        {(displayUser?.joining_date !== null && displayUser?.joining_date !== "") ? displayUser.email : "--"}
                        {
                          displayUser?.email && displayUser?.email.length > 19 && <Tooltip id={`name-tooltip-${displayUser.id}`} />
                        }
                      </div>
                    </div>
                    <div className="field-label half-field">
                      <label className="label-title">Program</label>
                      {(() => {
                        let programsList = "";
                        if (displayUser.assign_classes) {
                          programsList = displayUser.assign_classes
                            .split(",")
                            .map((subProgram) =>
                              programs.find((program) => subProgram.trim() === program.id.toString())
                            )
                            .filter(Boolean)
                            .map((program) => program.program_name)
                            .join(", ");
                        }
                        return (
                          <div
                            className="label-filled"
                            data-tooltip-id={`program-tooltip-${displayUser.id}`}
                            data-tooltip-content={programsList}
                            data-tooltip-place="bottom"
                          >
                            {programsList !== "" ? (
                              programsList.length > 19 ? (
                                <>
                                  {programsList.slice(0, 19) + "..."}
                                  <Tooltip id={`program-tooltip-${displayUser.id}`} />
                                </>
                              ) : (
                                programsList
                              )
                            ) : (
                              "---"
                            )}
                          </div>
                        );
                      })()}
                    </div>
                    <div className="field-label half-field">
                      <label className="label-title">Phone Number</label>
                      <div className="label-filled">{displayUser.phone}</div>
                    </div>
                    <div className="field-label half-field">
                      <label className="label-title">Date of Birth</label>
                      <div className="label-filled">
                        {(displayUser?.dob !== null && displayUser?.dob !== "") ? convertDBDateFormat(String(displayUser.dob).split("T")[0]) : "--"}
                      </div>
                    </div>
                    <div className="field-label half-field">
                      <label className="label-title">Date of Joining</label>
                      <div className="label-filled">
                        {(displayUser?.joining_date !== null && displayUser?.joining_date !== "") ? convertDBDateFormat(String(displayUser.joining_date).split("T")[0]) : "--"}

                      </div>
                    </div>
                    <div className="field-label full-field">
                      <label className="label-title">Address</label>
                      <div className="label-filled">{displayUser.address}</div>
                    </div>
                  </div>
                </div>

                <div className="sp-details-box">
                  <div className="sp-tt ft-14 flexc">
                    <i className="icon-Invites-Outline" />
                    Roles &amp; Permissions
                  </div>
                  <div className="form-section-default flexc justifySp fwrap">
                    <div className="field-label full-field">
                      <label className="label-title">Role</label>
                      <div className="label-filled">
                        {roles.map((role) =>
                          role.id === parseInt(displayUser.role_id) ? role.role_name : null
                        )}
                      </div>
                    </div>
                    <div className="field-label full-field">
                      <label className="label-title">Permissions</label>
                      <div className="flexc label-fill-value fwrap gap-10">
                        {displayUser.permissions_id && (
                          <div className="full-field">
                            {(() => {
                              const userRole = roles.find((role) => role.id === parseInt(displayUser.role_id));
                              if (!userRole) return null;
                              switch (userRole.role_name) {
                                case 'Administrator':
                                  return (
                                    <div>
                                      <div className="label-permissions mt-3 cur_norml">
                                        <button className="sp-selected-bt ft-wt600 ft-13 mr-2">Manage Student & Staff</button>
                                        <button className="sp-selected-bt ft-wt600 ft-13 mr-2 mt-3">Full Access to all features</button>
                                        <button className="sp-selected-bt ft-wt600 ft-13 mr-2">Manage Program</button>
                                        <button className="sp-selected-bt ft-wt600 ft-13 mr-2 mt-3">Manage Fees</button>
                                        <button className="sp-selected-bt ft-wt600 ft-13 mr-2">Admin Control</button>
                                      </div>
                                    </div>
                                  );
                                case 'Teacher':
                                  return (
                                    <div>
                                      <div className="label-permissions mt-3 cur_norml">
                                        <button className="sp-selected-bt ft-wt600 ft-13 mr-2">Manage Student</button>
                                        <button className="sp-selected-bt ft-wt600 ft-13 mr-2">Full Access to all features</button>
                                      </div>
                                    </div>
                                  );
                                case 'Security':
                                  return (
                                    <div>
                                      <div className="label-permissions mt-3 cur_norml">
                                        <button className="sp-selected-bt ft-wt600 ft-13 mr-2">Scan QR</button>
                                        <button className="sp-selected-bt ft-wt600 ft-13 mr-2">Add Visitor</button>
                                      </div>
                                    </div>
                                  );
                                case 'Driver':
                                  return (
                                    <div>
                                      <div className="label-permissions mt-3 cur_norml">
                                        <button className="sp-selected-bt ft-wt600 ft-13 mr-2">Pickup Detail</button>
                                      </div>
                                    </div>
                                  );
                                case 'Helper':
                                  return (
                                    <div>
                                      <div className="label-permissions mt-3 cur_norml">
                                        <button className="sp-selected-bt ft-wt600 ft-13 mr-2">Scan QR</button>
                                        <button className="sp-selected-bt ft-wt600 ft-13 mr-2">Pickup Details</button>
                                      </div>
                                    </div>
                                  );
                                default:
                                  return null;
                              }
                            })()}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="sp-details-box">
                  <div className="sp-tt ft-14 flexc">
                    <i className="icon-Invites-Outline" />
                    Emergency Contact
                  </div>
                  <div className="form-section-default flexc justifySp fwrap">
                    <div className="field-label full-field text-center">
                      <label className="label-title">No Contact found</label>
                      <div className="margin_top10p label-fill-value">
                        <button className="sp-selected-bt ft-wt400 ft-13 inline-flex items-center">
                          <i className="icon-Button-Plus" />
                          Add
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="sp-details-box">
                  <div className="sp-tt ft-14 flexc ft-wt600">
                    <i className="icon-Invites-Outline" />
                    Attendance Detail
                  </div>
                  <div className="attendance-details">
                    <div style={{ width: '300px', height: '200px', position: 'relative' }}>
                      <Doughnut data={data} options={options} />
                      <div style={{
                        position: 'absolute',
                        top: '65%',
                        left: '50%',
                        transform: 'translate(-50%, -60%)',
                        textAlign: 'center'
                      }}>
                        <div className="total-present">{presentCount + absentCount + leaveCount}</div>
                        <p>Out of {totalDaysCount} Days</p>
                      </div>
                    </div>
                  </div>
                  <div className="field-label-op">
                    <div className="flexc justifySp">
                      <span className="flexc ft-13 gap-cus-img">
                        {/* <span className="sp-lpdr icon-sp-present mr-rt10" /> */}
                        <div style={{ fontSize: "21px" }}>
                          <span class="icon-List_Present">
                            <span class="path1"></span>
                            <span class="path2"></span>
                          </span>
                        </div>
                        Present
                      </span>
                      <span className="ft-13">{presentCount} Days</span>
                    </div>
                    <div className="flexc justifySp  margin_20p">
                      <span className="flexc ft-13 gap-cus-img">
                        {/* <span className="notapp-ele mr-rt10" /> */}
                        <div >
                          <span class="icon-List_Absent" style={{ fontSize: "21px" }}>
                            <span class="path1"></span>
                            <span class="path2"></span>
                            <span class="path3"></span>
                            <span class="path4"></span>
                            <span class="path5"></span>
                            <span class="path6"></span>
                          </span>
                        </div>
                        Leave
                      </span>
                      <span className="ft-13">{absentCount} Days</span>
                    </div>
                    <div className="flexc justifySp  margin_20p">
                      <span className="flexc ft-13 gap-cus-img">
                        {/* <span className="cross-ele mr-rt10" /> */}
                        <img src={pending} alt="" style={{ width: '16px' }} />
                        Leave Approval Pending
                      </span>
                      <span className="ft-13">{leaveCount} Days</span>
                    </div>
                  </div>
                </div>

              </div>
            </div>
            : <div className="popup-student-details">
            </div>
        }
        {/* Recycle Bin Modal */}
        {isRecycleBinVisible && (
          <div
            id="recycle-popup"
            tabIndex={-1}
            className="back_overlay fixed top-0 right-0 left-0 z-50 flex justify-center items-center w-full  max-h-full res_tot_div"
          >
            <div className="relative w-full max-w-xs max-h-full">
              <div className="relative bg-white rounded-lg shadow dark:bg-gray-700 p-4">
                {/* <button
             type="button"
             onClick={handleRecycleBinClose}
             className="absolute top-3 end-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
             data-modal-hide="recycle-popup"
           >
             <svg
               className="w-3 h-3"
               aria-hidden="true"
               xmlns="http://www.w3.org/2000/svg"
               fill="none"
               viewBox="0 0 14 14"
             >
               <path
                 stroke="currentColor"
                 strokeLinecap="round"
                 strokeLinejoin="round"
                 strokeWidth={2}
                 d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
               />
             </svg>
             <span className="sr-only">Close modal</span>
           </button> */}
                <div className="p-4 md:p-5 text-center">
                  <div className="round-btn rounded inline-flex alignC justify-center">
                    {/* <i className="icon-delete" /> */}
                    <span class="icon-Recycle_Modal"></span>
                  </div>
                  <p className="ft-wt600 ft-16 mb-4 mt-3">Recycle Bin</p>
                  <h3 className="mb-5 font-normal text-sm">
                    Are you sure you want to move this student to Recycle Bin?
                  </h3>
                  <button
                    onClick={handleSaveRecycleBin}
                    type="button"
                    className="yes_button_sta_cus sp-red-color text-white  hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 rounded-md  inline-flex items-center px-5 py-2.5 text-center"
                  >
                    Yes, Move
                  </button>
                  <button
                    onClick={handleRecycleBinClose}
                    type="button"
                    style={{
                      border: "1px solid #D7D9DD",
                    }}
                    className="can_cus_rec border-full ms-3 py-2.5 px-5  text-gray-900 focus:outline-none bg-white rounded-md border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700"
                  >
                    Cancel
                  </button>

                </div>
              </div>
            </div>
          </div>
        )}
        {/* New Program Popup */}
        {isNewProgramVisible && (
          <div
            id="newprogram-popup"
            tabIndex={-1}
            className="back_overlay fixed top-0 right-0 left-0 z-50 flex justify-center items-center w-full max-h-full res_tot_div"
          >
            <div className="relative w-full max-w-md max-h-full">
              <div className="relative bg-white rounded-lg shadow dark:bg-gray-700 p-4">
                <div className="p-4 md:p-5">
                  <p className="ft-wt600 ft-16 mb-4">New Program</p>
                  <div className="mb-5">
                    <label className="mb-5 font-normal text-sm">Programs</label>
                    <div
                      className="field-value-holder dropbutton"
                      style={{
                        width: "100%",
                        borderColor: newProgramError ? "red" : borderColor.newProgram,
                      }}

                      onFocus={() => handleFocus("newProgram")}
                      onBlur={handleBlur}
                    >
                      <Dropdown
                        label={
                          <span
                            style={{ color: "#707070", fontSize: "0.8825em" }}
                          >
                            {selectedNewProgram
                              ? `${selectedNewProgram.program_name.slice(0, 40)}...`
                              : "Select a Program"}
                          </span>
                        }
                        style={{ width: "100%" }}
                      >
                        {programs.map((item) => (
                          <Dropdown.Item
                            // className={
                            //   selectedNewProgram && selectedNewProgram.id === item.id
                            //     ? "dd_select"
                            //     : null
                            // }
                            className={`${selectedNewProgram && selectedNewProgram.id === item.id ? "selected-item dd_select" : ""}`}
                            icon={
                              selectedNewProgram && selectedNewProgram.id === item.id
                                ? HiCheck
                                : null
                            }
                            onClick={() =>
                              setNewProgram(parseInt(item.id))
                            }
                            key={item.id}
                          >
                            <div                             
                              data-tooltip-id={`program-tooltip-${item.id}`}
                              data-tooltip-content={item.program_name}
                              data-tooltip-place="bottom"
                              >
                              {
                                (item.program_name.length <= 20) ? item.program_name : `${item.program_name.slice(0, 20)}...`
                              }
                              </div>
                              {item.program_name.length > 20 && <Tooltip id={`program-tooltip-${item.id}`} />}
                          </Dropdown.Item>
                        ))}
                      </Dropdown>
                    </div>
                    {newProgramError && (
                      <span className="error">
                        {newProgramError}
                      </span>
                    )}
                  </div>
                  <div className="text-right but_new_cu">
                    <button
                      onClick={handleNewProgramClose}
                      type="button"
                      style={{
                        border: "1px solid #D7D9DD",
                      }}
                      className="can_pro_but border-full py-2.5 px-5 ft-13 text-gray-900 focus:outline-none bg-white rounded-md border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 cancel_div_di "
                    >
                      Cancel
                    </button>
                    <button
                      onClick={handleSaveNewProgram}
                      type="button"
                      className="save_button_cus sp-blue-color text-white ms-3 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 rounded-md ft-13 inline-flex items-center px-5 py-2.5 text-center save_cus_di"
                    >
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {/* Create New Program */}
        <ProgramAddComponent
          newProgramPopupVisible={newProgramPopupVisible}
          setNewProgramPopupVisible={setNewProgramPopupVisible}
          teachersData={teachersData}
          setProgramsData={setProgramsData}
          newProgramAddUpdateStatus={newProgramAddUpdateStatus}
          setNewProgramAddUpdateStatus={setNewProgramAddUpdateStatus}
        />

      </div>
      {/* Invite all Staff */}
      {/* {showConfetti && (
          <div style={{ position: 'relative', height: '100vh' }}>
            <Confetti
              width={window.innerWidth}
              height={window.indexedDB}
              numberOfPieces={200}
              recycle={false}
              onConfettiComplete={() => setShowConfetti(false)}
            />
          </div>
        )} */}
    </section >
    //   {showConfetti && (
    //     <Confetti
    //       width={width}
    //       height={height}
    //       paperCount={400}
    //       numberOfPieces={500}
    //       recycle={false}
    //       // ribbonParticleDrag={10}
    //       // ribbonParticleMass={1}
    //       // ribbonParticleAngle={45}
    //       // ribbonParticleThick={8.0}
    //       // ribbonParticleDist={8.0}
    //       // ribbonParticleCount={30}
    //       ribbonCount={20}
    //     />
    //   )}
    // </CelebrationWrapper>
  );
}

// const CelebrationWrapper = styled.div`
//   position: absolute;
//   height: 100vh;
//   width:100vw;
//   display: flex;
//   // flex-direction: column;
//   // align-items: center;
//   // justify-content: center;
//   background: rgba(255, 255, 255, 0.8);
//   `;




